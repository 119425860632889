<template>
  <div>
    <div class="cover-header" v-if="!no_header">
      <b-img v-if="!isVideo(first_image)" :src="first_image"></b-img>
      <video :src="first_image" v-else></video>
      <div class="avatar-cover-header">
        <b-avatar
          size="114"
          class="p-05"
          variant="light"
          :src="verifyAsset(chat.creator.avatar)"
        />
      </div>
    </div>
    <b-row class="m-0 px-1">
      <b-col class="p-1 box-state mb-2 col-12" v-if="!no_header">
        {{chat.proposal.title}}
      </b-col>
      <div v-if="is_invoice && chat.user_transaction" class="w-100">
        <b-col class="p-1 box-state mb-2 col-12">
          <div class="d-flex justify-content-between border-bottom py-1">
            <p class="m-0">{{ $t('creator.orderId') }} {{chat.user_transaction.order_id}}</p>
            <p class="m-0 text-muted">{{getDatev2(new Date(`${chat.user_transaction.paid_at}T00:00:00-06:00`))}}<b-link v-if="chat.user_proposal.link_invoice" :href="chat.user_proposal.link_invoice" target="_blank" class="btn-pdf">PDF</b-link></p>
          </div>
          <div class="border-bottom">
            <div class="d-flex justify-content-between">
              <p class="m-0">{{getTranslateType()}} + {{getTranslateContent()}}</p>
              <p class="m-0">${{numberFormat(parseFloat(chat.user_transaction.amount), true)}}</p>
            </div>
            <p class="d-flex flex-wrap type-sale mt-1">
              <span class="w-100 align-items-center mr-05">
                <feather-icon icon="CalendarIcon" class="mr-02" size="11"/>
                {{deal_buyed.days_delivery}} {{ $t('creator.daysDelivery') }}
              </span>
              <span class="w-100 align-items-center mr-05">
                <feather-icon icon="RepeatIcon" class="mr-02" size="10"/>
                {{deal_buyed.revision}} {{ $t('creator.dealRevision') }}
              </span>
              <span class="w-100 align-items-center mr-1">
                <b-icon icon="shield-check" class="mr-02"/>
                {{$t('creator.licensing').find((licence) => licence.value === deal_buyed.licensing).text}}
              </span>
            </p>
          </div>
          <div class="d-flex justify-content-between border-bottom py-1">
            <div>
              <p class="m-0">{{ $t('creator.dealSubtotal') }}</p>
              <p class="m-0 f-13 text-muted">{{ $t('creator.tax') }} 
                {{`(${chat.user_transaction.service_fee}%)`}}
              </p>
            </div>
            <div>
              <p class="m-0 f-13">${{numberFormat(parseFloat(chat.user_transaction.amount) - (parseFloat(chat.user_transaction.amount) / 100) * chat.user_transaction.service_fee, true)}}</p>
              <p class="m-0 f-13 text-muted">${{numberFormat((parseFloat(chat.user_transaction.amount) / 100) * chat.user_transaction.service_fee, true)}}</p>
            </div>
          </div>
          <div class="d-flex justify-content-between pt-1">
            <p class="m-0">{{ $t('creator.totalAmount') }}</p>
            <p class="m-0">${{numberFormat(parseFloat(chat.user_transaction.amount), true)}}</p>
          </div>
        </b-col>
        <b-col class="p-1 box-state mb-2 col-12">
          <div class="border-bottom py-1">
            <p class="m-0">{{ $t('creator.payoutSummary') }}</p>
          </div>
          <div class="d-flex justify-content-between border-bottom py-1">
            <p class="m-0">{{ $t('creator.orderId') }} {{chat.user_transaction.order_id}}</p>
            <p class="m-0 text-muted">${{numberFormat(parseFloat(chat.user_transaction.amount), true)}}</p>
          </div>
          <div class="d-flex justify-content-between border-bottom py-1">
            <div>
              <p class="m-0">{{ $t('creator.totalPayout') }}</p>
              <small class="f-11">{{ $t('creator.payoutDate') }} {{chat.user_transaction.paid_at}}</small>
            </div>
            <p class="m-0 text-muted">${{numberFormat(parseFloat(chat.user_transaction.amount), true)}}</p>
          </div>

        </b-col>
      </div>
      <div v-else-if="chat.order_status.length > 0" class="w-100">
        <b-row class="m-0">
          <b-col class="p-1 box-state col-12 mb-2">
            <div 
              :class="`d-flex justify-content-between align-items-center ${index !== chat.order_status.length - 1 ? 'mb-1' : ''}`" 
              v-for="(order, index) in chat.order_status" 
              :key="order.uuid"
            >
              <div class="d-flex align-items-center">
                <b-avatar :icon="getIconStatus(order.status)" class="mr-1" :variant="order.is_completed ? 'success' : 'warning'"></b-avatar>
                <div>
                  <span class="d-block">{{$t(`creator.${order.status}`)}}</span>
                  <small>{{order.is_completed ? $t('creator.completed') : $t('creator.incompleted')}}</small>
                </div>
              </div>
              <span class="text-muted">{{order.completed_at ? getDatev2(new Date(order.completed_at)) : '-'}}</span>
            </div>
          </b-col>
          <b-col class="p-1 box-state col-12">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <b-avatar icon="clock-history" class="mr-1" variant="warning"></b-avatar>
                <span class="d-block">{{ $t('creator.revisionPendding') }}</span>
              </div>
              <span>{{chat.revisions_remaining}}</span>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-row>
  </div>
</template>

<script>
import {
  BLink,
  BImg,
  BAvatar,
  BRow,
  BCol,
} from 'bootstrap-vue';
import { verifyAsset, getAssetUrl } from '@/libs/utils/urls';
import { getDatev2 } from '@/libs/utils/times';
import { numberFormat, isVideo } from '@/libs/utils/formats';

export default {
  name: 'StateSale',
  components: {
    BLink,
    BImg,
    BAvatar,
    BRow,
    BCol,
  },
  props: {
    chat_dad: {
      type: Object,
    },
    is_invoice: {
      type: Boolean,
      default: false,
    },
    no_header: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      getAssetUrl,
      verifyAsset,
      getDatev2,
      numberFormat,
      isVideo,
      chat: this.chat_dad,
      deal_buyed: this.chat_dad.user_proposal ? this.chat_dad.proposal.deals.find((deal) => deal.uuid === this.chat_dad.user_proposal.deal) : null,
      first_image: null,
    }
  },
  created() {
    let image_media_first = {};
    if (this.chat.proposal.images.length > 0) {
      image_media_first = this.chat.proposal.images.reduce((prev, curr) => {
        return prev.sort < curr.sort ? prev : curr;
      });
      this.first_image = this.chat.proposal.images.length > 0 ? !image_media_first.media ? getAssetUrl(image_media_first.media) : image_media_first.media : require("@/assets/images/dashboard/imgDefault.png");
    }
  },
  methods: {
    getTranslateType() {
      return this.$t(`creator.type_of_work`).find((type) => type.value === this.chat.proposal.work_type).text;
    },
    getTranslateContent() {
      return this.$t(`creator.focus_in_${this.chat.proposal.work_type}`).find((type) => type.value === this.chat.proposal.content_type).text;
    },
    getIconStatus(status) {
      const types = {
        'founded': 'credit-card',
        'accepted': 'check-circle',
        'creating': 'pencil-square',
        'review': 'card-checklist',
        'declined': 'emoji-frown',
        'refunded': 'arrow-clockwise',
      }
      if (types[status]) return types[status]
      return ''
    }
  },
}
</script>

<style scoped>
.btn-pdf{
  color: #55a6c4 !important;
  margin-left: 3px;
  cursor: pointer;
}
.mr-02 {
  margin-right: 0.2em;
}
.type-sale {
  font-size: 10px;
  color: #6C757D;
}
.f-13 {
  font-size: 13px;
}
.f-11 {
  font-size: 11px;
}
.cover-header {
  height: 170px;
  position: relative;
}
.cover-header img, .cover-header video {
  width: 100%;
  height: 120px;
  object-fit: cover;
}
.avatar-cover-header {
  position: absolute;
  top: 0;
  background-color: transparent;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.p-05 {
  padding: 0.5em;
}
.box-state {
  border: 1px solid #dedde2;
  border-radius: 1em;
  background-color: white;
}
.box-state small {
  font-family: Arial, Helvetica, sans-serif;
}
</style>