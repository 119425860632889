<template>
  <div class="d-flex position-relative" style="height: inherit">
    <div class="flex-auto-container">
      <div class="chat-navbar">
        <header class="chat-header">

          <!-- Avatar & Name -->
          <div class="d-flex align-items-center">
            <b-avatar
              size="36"
              :src="verifyAsset(sender.avatar)"
              class="mr-1 badge-minimal"
            />
            <div class="d-none d-sm-flex flex-wrap">
              <h6 class="mb-0 username-buy">
                {{ sender.first_name }} {{ sender.last_name }}
              </h6>
              <b-tooltip target="campaign" triggers="hover" placement="button">{{$t('creator.about')}} {{chat.casting_call ? chat.casting_call.title : chat.proposal.title }} </b-tooltip>
              <p id="campaign" class="mb-0 avenir-medium text-overflow-title-chat-proposals">{{$t('creator.about')}} {{chat.casting_call ? chat.casting_call.title : chat.proposal.title }} </p>
            </div>
          </div>


          <!-- Contact Actions -->
          <div class="d-flex align-items-center">
            <b-button variant="flat-secondary" id="update_chat" @click="updateChat()">
              <feather-icon
                icon="RefreshCwIcon"
                size="17"
              />
              <b-tooltip triggers="hover" target="update_chat" placement="bottom">{{ $t('creator.updateChat') }}</b-tooltip>
            </b-button>
            <b-button variant="flat-secondary" id="detail_proposal" @click="openSidebarInfo()" class="d-xl-none btn-open-sider">
              <feather-icon
                icon="InfoIcon"
                size="17"
              />
              <b-tooltip triggers="hover" target="detail_proposal" placement="bottom">{{ $t('creator.detailProposal') }}</b-tooltip>
            </b-button>
          </div>
        </header>
      </div>

      <!-- User Chat Area -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="user-chats scroll-area"
        ref="refChatLogPS"
      >
        <div class="chats">
          <div
            v-for="(msg, index) in messages_format"
            :key="msg.sender.id + String(index)"
            class="chat"
            :class="{'chat-left': msg.sender.id !== receiver.id || msg.type_message === 'automated'}"
          >
            <revision-chat 
              v-if="msg.type_message === 'revision'" 
              :message="msg" 
              :revisions_remaining="chat.revisions_remaining"
              :chat="chat"
              class="p-1"
            ></revision-chat>
            <div v-else>
              <div :class="`d-flex justify-content-center ${index === 0 ? 'm-0' : 'my-3'}`" v-if="msg.is_same_date">
                <p class="badge-date-chat">{{getFormatTimeDifferenceChat(msg.sended_at)}}</p>
              </div>
              <div class="chat-avatar">
                <b-avatar
                  size="36"
                  :src="msg.type_message === 'automated' ? require('@/assets/images/mascot/dil_small.jpg') : msg.sender.id === receiver.id ? verifyAsset(receiver.profile_image) : verifyAsset(msg.sender.avatar)"
                />
              </div>
              <div class="chat-body position-relative" ref="chat_body">
                <div :class="`chat-content ${msg.type_message === 'automated' ? 'chat-bot' : ''} mt-1`">
                  <div v-if="msg.type_message === 'text'" class="">
                    <p v-html="formatTextLinks(msg.message)"></p>
                  </div>
                  <div v-else-if="msg.type_message === 'automated'">
                    <p>{{ msg.message }}</p>
                  </div>
                  <div v-else-if="msg.type_message === 'file'">
                    <a class="d-flex" :href="verifyAsset(msg.file)" target="_blank">
                      <div class="d-flex">  
                        <feather-icon
                          icon="FileIcon"
                          size="17"
                          class="cursor-pointer d-sm-block d-none mr-1"
                        />
                        <p>{{ $t('creator.viewArchive') }}</p>
                      </div>
                    </a>
                  </div>
                  <div v-else-if="msg.type_message === 'content'">
                    <a :class="`d-flex text-dark`" :href="verifyAsset(msg.file)" target="_blank">
                      <div class="d-flex">  
                        <feather-icon
                          icon="FileIcon"
                          size="17"
                          class="cursor-pointer d-sm-block d-none mr-1"
                        />
                        <p>{{ $t('creator.viewArchive') }}</p>
                      </div>
                    </a>
                  </div>
                  <div v-else-if="msg.type_message === 'link'">
                    <div :class="`d-flex text-dark`">
                      <div class="d-flex">  
                        <feather-icon
                          icon="LinkIcon"
                          size="17"
                          class="cursor-pointer d-sm-block d-none mr-1"
                        />
                        <p v-html="formatTextLinks(msg.message)"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hour-message-container">
                <span class="hour-message">
                  <div class="">
                    {{(new Date(msg.sended_at)).toLocaleTimeString().slice(0, 5)}}
                    <b-icon 
                      icon="check2-all" 
                      :class="{'seen-color': msg.seen}" 
                      class="check-icon-seen" 
                      v-if="msg.seen !== undefined && isRightSide(msg.sender_id)"
                    ></b-icon>
                  </div>
                </span>
              </div>
            </div>

          </div>
          <!-- CONTAINER EXISTS AN EMAIL OR PHONE IN MESSAGE -->
          <div class="container-msg-restrincted" v-if="existsEmailOrPhoneOrUlr(message, !chat.is_saled)">
            <div class="bar-color"></div>
            <p>Detectamos que tu mensaje contiene un enlace externo. Si tiene información de contacto, BrandMe puede suspender tu cuenta.</p>
            <div class="d-flex justify-content-between">
              <span>Elige "Editar" para modificar tu mensaje</span>
              <b-link @click="message = ''">Editar</b-link>
            </div>
          </div>
          <!-- -------------------------------------------- -->
          <div v-if="chat.is_saled" class="chat chat-left w-100">
            <div class="w-100" v-if="seller_status === 'pending'">
              <accpet-decline-chat @accept_chat="updateChat" @declined_chat="declinedChat" v-if="receiver.id === chat.creator.id" :chat="chat"/>
            </div>
            <div class="w-100" v-if="seller_status === 'approved'">
              <upload-contents-chat v-if="status_contents === 'creating' && receiver.id === chat.creator.id" :chat="chat" @to_bottom="scrollToBottomInChatLog" @uploaded_contents="updateChat"/>
              <upload-links-chat class="mt-1" v-if="status_contents === 'send_links' && receiver.id === chat.creator.id" :chat="chat" @to_bottom="scrollToBottomInChatLog" @uploaded_contents="updateChat"/>
              <review-contents-chat v-if="status_contents === 'review' && !is_completed_status && receiver.id === chat.brand.id" :chat="chat" @update_chat="updateChat"/>
              <review-links-chat v-if="status_contents === 'approved_links' &&!is_completed_status && receiver.id === chat.brand.id" :chat="chat" @update_chat="updateChat"/>
            </div>
            <div class="w-100" v-if="chat.is_brandme_talent && checkIsRequestingPrice()">
              <request-price v-if="receiver.id === chat.brand.id" :chat="chat" @update_chat="updateChat"/>
            </div>
          </div>
        </div>
      </vue-perfect-scrollbar>

      <!-- Message Input -->
      <b-form
        v-if="can_send_message"
        class="chat-app-form position-relative"
        @submit.prevent="sendMessage"
      >
        <upload-files @send_files="sendFiles" @close_upload_files="show_upload_files = false" v-if="show_upload_files"/>
        <b-input-group class="input-group-merge form-send-message mr-1">
          <b-form-input
            v-model="message"
            :placeholder="$t('creator.enterMessage')"
            :disabled="loaded_files || existsEmailOrPhoneOrUlr(message, !chat.is_saled)"
            class="input-file-send-message"
            autocomplete="off"
          />
          <div class="container-badges-files" v-if="files.length > 0">
            <div class="loader-uploading-files" v-if="loaded_files">
              <b-spinner class="spinner-uploading-files"></b-spinner>
            </div>
          </div>
        </b-input-group>
        <b-button
          variant="send-message-chat"
          type="submit"
          class="send-message-chat px-1"
          ref="send-message-chat"
          :disabled="(message.length === 0 && files.length === 0) || existsEmailOrPhoneOrUlr(message, !chat.is_saled)"
        >
          <b-img src="@/assets/images/icons/message.svg" class="message-icon-svg"></b-img>
        </b-button>
      </b-form>

      <div class="chat-app-form" v-if="!can_send_message && !chat.is_brandme_talent">
        <message-decline-chat/>
      </div>

      <div class="bg-white" v-if="!can_send_message && chat.is_brandme_talent && receiver.id === chat.brand.id && free_plan">
        <upgrade-plan/>
      </div>
    </div>
    <b-sidebar v-model="open_modal" right shadow backdrop class="sidebar-chat" scroll>
      <template #header>
        <div class="dropdown-sales-options-sidebar">
          <dropdown-chat-states 
            :chat="chat" 
            :proposals_user="proposals_user" 
            :option_selected_dropdown="option_selected_dropdown"
            @change_dropdown="changeDropdown"
            @change_proposal="changeProposal"
            class="p-1"
          ></dropdown-chat-states>
        </div>
      </template>
      <preview :showEdit="false" :proposal_dad="chat.proposal" :class_dad="'p-1'" v-if="chat.proposal && option_selected_dropdown === 'proposal_detail'" :is_sidebar="true"/>
      <detail-casting-call 
        :show_title="false" 
        :casting_call="chat.casting_call"
        v-if="chat.casting_call && option_selected_dropdown === 'casting_call_detail'"
        :is_sidebar="true"
      />
      <state-sale 
        v-if="option_selected_dropdown === 'order_status' || option_selected_dropdown === 'order_invoice'" 
        class="w-100 p-1" 
        :chat_dad="chat"
        :is_invoice="option_selected_dropdown === 'order_invoice'"
      ></state-sale>
    </b-sidebar>
    <div class="container-right-side d-none d-xl-block">    
      <div class="dropdown-sales-options">
        <dropdown-chat-states 
          :chat="chat" 
          :proposals_user="proposals_user" 
          :option_selected_dropdown="option_selected_dropdown"
          @change_dropdown="changeDropdown"
          @change_proposal="changeProposal"
        ></dropdown-chat-states>
      </div>
      <preview 
        :showEdit="false" 
        :proposal_dad="proposal_active"
        :class_dad="'px-1'"
        class="width-proposal-right-chat"
        v-if="proposal_active && option_selected_dropdown === 'proposal_detail'" 
        :is_sidebar="true"
        :key="update_preview"
      />
      <detail-casting-call 
        :show_title="false" 
        :casting_call="chat.casting_call" 
        class="width-proposal-right-chat" 
        v-if="chat.casting_call && option_selected_dropdown === 'casting_call_detail'"
        :is_sidebar="true"
      />
      <state-sale 
        v-if="option_selected_dropdown === 'order_status' || option_selected_dropdown === 'order_invoice'" 
        class="width-proposal-right-chat" 
        :chat_dad="chat"
        :is_invoice="option_selected_dropdown === 'order_invoice'"
      ></state-sale>
    </div>
    <div class="loader-inner-active-chat" v-if="inner_loader">
      <b-spinner class="spinner-inner-active-chat"></b-spinner>
    </div>

    <modal-brandme size="sm" :show_modal="show_modal_url" :key="update_modal_url">
      <template #body>
        <div class="text-center">
          <h2>Estás por salir de BrandMe</h2>
          <p class="avenir-medium">Estás por ingresar a {{ url_to_go }}</p>
          <p class="avenir-medium">¿Estas seguro que quieres continuar?</p>
  
          <b-button variant="blue-button" class="blue-button mb-1" @click="goToUrl()">Continuar</b-button>
        </div>
      </template>
    </modal-brandme>
  </div>
</template>

<script>
import { 
  BTooltip,
  BAvatar,
  BForm,
  BInputGroup,
  BFormInput,
  BButton,
  BSidebar,
  BSpinner,
  BLink,
  BImg,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import chat_service from '@/services/chat';
import preview from '@/views/pages/proposals/stepper/preview.vue';
import StateSale from '@/views/components/chat/StateSale.vue';
import accpetDeclineChat from './accpetDeclineChat.vue';
import messageDeclineChat from './messageDeclineChat.vue'
import uploadContentsChat from './uploadContentsChat.vue'
import uploadFiles from './uploadFiles.vue'
import reviewContentsChat from './reviewContentsChat.vue';
import revisionChat from '@/views/pages/proposals/chat/revisionChat.vue';
import detailCastingCall from '../../casting-calls/pitch-stepper/detailCastingCall.vue';
import uploadLinksChat from './uploadLinksChat.vue';
import reviewLinksChat from './reviewLinksChat.vue';
import requestPrice from './requestPrice.vue'
import { getUserData } from '@/libs/utils/user';
import { existsEmailOrPhoneOrUlr } from '@/libs/utils/formats'
import { verifyAsset } from '@/libs/utils/urls'
import { getFormatTimeDifferenceChat, getDatev2 } from '@/libs/utils/times'

import {
  nextTick
} from '@vue/composition-api'
import upgradePlan from './upgradePlan.vue';

export default {
  name: 'activeChat',
  components: {
    BImg,
    BTooltip,
    BAvatar,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BSpinner,
    BSidebar,
    BLink,
    VuePerfectScrollbar,
    preview,
    StateSale,
    accpetDeclineChat,
    messageDeclineChat,
    uploadContentsChat,
    reviewContentsChat,
    revisionChat,
    detailCastingCall,
    uploadFiles,
    uploadLinksChat,
    reviewLinksChat,
    dropdownChatStates: () => import('@/views/components/chat/dropdownChatStates.vue'),
    ModalBrandme: () => import('@/views/components/modal/ModalBrandme.vue'),
    requestPrice,
    upgradePlan
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
    inner_loader: {
      type: Boolean,
      default: false,
    },
    proposals_user: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      getUserData,
      getFormatTimeDifferenceChat,
      verifyAsset,
      existsEmailOrPhoneOrUlr,
      seller_status: '',
      message: '',
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      messages_format: [],
      receiver: getUserData(),
      sender: {},
      files: [],
      open_modal: false,
      loaded_files: false,
      data_changed: null,
      option_selected_dropdown: this.chat.casting_call ? 'casting_call_detail' : this.chat.is_saled ? 'order_status' : 'proposal_detail',
      can_send_message: true,
      status_contents: '',
      is_completed_status: false,
      show_upload_files: false,
      ready_to_send_ws: true,
      proposal_active: {},
      update_preview: false,
      show_modal_url: false,
      update_modal_url: false,
      url_to_go: '',
      links: [],
      free_plan: false
    }
  },
  created() {
    this.setSender()
    this.formatMessages()
    if (this.chat.user_proposal) this.seller_status = this.chat.user_proposal.seller_status;
    else if (!this.chat.user_proposal && this.chat.casting_call) this.seller_status = 'approved'
    else this.seller_status = '';
    
    this.can_send_message = this.getCanSendMessage();
    this.status_contents = !this.chat.is_brandme_talent && this.chat.is_saled ? this.chat.order_status[this.chat.order_status.length - 1].status : ''
    this.is_completed_status = !this.chat.is_brandme_talent && this.chat.is_saled ? this.chat.order_status[this.chat.order_status.length - 1].is_completed : false
    setTimeout(() => {
      nextTick(() => { this.scrollToBottomInChatLog() })
    }, 1)
    this.proposal_active = this.chat.proposal;

    this.checkFrrePlan()

    this.$root.$on('new_message', (data) => {

      if (this.chat.uuid === data.body.chat_uuid) {
        const ready_message = this.messages_format.find(msg => msg.uuid === data.body.message_uuid)

        if (!ready_message) {
          const obj = {
            uuid: data.body.message_uuid,
            sender: this.sender,
            message: data.body.message,
            type_message: 'text',
            sended_at: Date.now(),
            file: '',
            is_same_date: false
          }

          this.messages_format.push(obj)

          setTimeout(() => {
            nextTick(() => { this.scrollToBottomInChatLog() })
          }, 1)

          chat_service.markAsReaded(this.chat.uuid).then(() => {
          })
        }
      }
    });

    this.$root.$on('chat_active', (data) => {
      if (this.chat.uuid === data.body.chat_uuid) {
        this.can_send_message = true;
      }
    });

    this.$root.$on('chat_viewed', (data) => {
      if (this.chat.uuid === data.body.detail.chat_uuid) {
        this.messages_format.forEach(msg => {
          msg.seen = true;
        })
      }
    });

    this.$root.$on('files_uploaded', (data) => {
      if (this.chat.uuid === data.body.detail.chat_uuid) {
        const results = []
        const vue = this;
        data.body.detail.detail_chat.messages.forEach((msg) => {
          const obj = this.setFormatMsg(msg)
          vue.data_changed = getDatev2(new Date(msg.sended_at));
          results.push(obj)
        }),
        this.messages_format = results
      }
    });

    setTimeout(() => {
      this.links = document.getElementsByClassName('link-url-chat')
      this.links.forEach(link => {
        link.addEventListener('click', this.openModal);
      });
    }, 50);
  },
  methods: {
    checkFrrePlan() {
      this.free_plan = this.receiver.current_plan.name === 'Free'
    },
    checkIsRequestingPrice() {
      const review_status = this.chat.order_status.find((i) => i.status === 'price_requesting');
      return !review_status.is_completed;
    },
    formatTextLinks(text) {
      const urlRegex = /((https?:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)\b/gi
      return text.replace(urlRegex, '<a style="text-decoration: underline; color: #0096fe;" class="link-url-chat">$1</a>');
    },
    openModal(value) {
      this.show_modal_url = true;
      this.update_modal_url = !this.update_modal_url;
      this.url_to_go = this.addProtocolToUrl(value.srcElement.innerText);
    },
    addProtocolToUrl(url) {
      if (!/^https?:\/\//i.test(url)) {
        url = `https://${url}`;
      }
      return url;
    },
    goToUrl() {
      window.location.href = this.url_to_go;
    },
    getCanSendMessage() {
      if (!this.chat.is_saled) return true;

      if (this.chat.is_brandme_talent) {
        if (this.receiver.id === this.chat.creator.id) {
          const review_status = this.chat.order_status.find((i) => i.status === 'price_requesting');
          return review_status.is_completed;
        }
        return this.chat.brand_can_message
      }

      // PROPOSAL CHATS
      if (this.chat.order_status.length > 0 && this.chat.user_proposal) {
        if (this.chat.user_proposal.seller_status === 'rejected') return false;

        const review_status = this.chat.order_status.find((i) => i.status === 'review');
        if (review_status) return !review_status.is_completed;
        else return true
      }

      // CASTING CALL CHAT
      if (this.chat.order_status.length > 0 && this.chat.casting_call) {
        const approved_links_status = this.chat.order_status.find((i) => i.status === 'approved_links');
        if (approved_links_status) return !approved_links_status.is_completed;
        else return true
      }
    },
    changeProposal(proposal) {
      this.proposal_active = proposal;
      this.update_preview = !this.update_preview;
    },
    changeDropdown(value) {
      this.option_selected_dropdown = value;
    },
    openSidebarInfo() {
      this.open_modal = true;
    },
    setSender() {
      this.sender = this.receiver.id === this.chat.brand.id ? this.chat.creator : this.chat.brand
    },
    setFormatMsg(msg) {
      const sender = this.chat.brand.id === msg.sender_id ? this.chat.brand : this.chat.creator;
      const obj = {
        uuid: msg.uuid,
        sender,
        message: msg.type_message === 'text' || msg.type_message === 'automated' || msg.type_message === 'revision' ? msg.message : msg.link,
        type_message: msg.type_message,
        sended_at: msg.sended_at,
        sender_id: msg.sender_id,
        file: msg.type_message === 'file' || msg.type_message === 'content' ? msg.file : '',
        is_same_date: this.data_changed !== getDatev2(new Date(msg.sended_at)),
        seen: msg.seen,
      }

      return obj
    },
    formatMessages() {
      const result = []
      const vue = this;
      this.chat.messages.forEach((msg) => {
        if (msg.type_message !== 'automated' || msg.variant_automated === 'bot') {
          const obj = this.setFormatMsg(msg)
          vue.data_changed = getDatev2(new Date(msg.sended_at));
          result.push(obj)
        } else if (msg.variant_automated === 'buyer' && this.chat.brand.id === this.receiver.id) {
          const obj = this.setFormatMsg(msg)
          vue.data_changed = getDatev2(new Date(msg.sended_at));
          result.push(obj)
        } else if (msg.variant_automated === 'seller' && this.chat.creator.id === this.receiver.id) {
          const obj = this.setFormatMsg(msg)
          vue.data_changed = getDatev2(new Date(msg.sended_at));
          result.push(obj)
        }
      });

      this.messages_format = result
    },
    sendFiles(files) {
      this.files = files;
      this.sendMessage();
    },
    sendMessage() {
      if (this.ready_to_send_ws && !existsEmailOrPhoneOrUlr(this.message, !this.chat.is_saled)) {
        this.ready_to_send_ws = false;
        if (this.files.length > 0) {
          const form_data = new FormData();
          this.files.forEach((file) => {
            form_data.append('files', file);
          });
          this.loaded_files = true;
          chat_service.uploadFilesInChat(this.chat.uuid, form_data).then((response) => {
            this.ready_to_send_ws = true;
            this.loaded_files = false;
            response.results.forEach((msj_response) => {
              const new_msg = {
                sender: {
                  id: this.receiver.id,
                  first_name: this.receiver.first_name,
                  last_name: this.receiver.last_name,
                  avatar: this.receiver.avatar,
                },
                message: '',
                type_message: 'file',
                sended_at: Date.now(),
                file: msj_response.file,
              }
              this.messages_format.push(new_msg)
            });
            this.message = '';
            this.files = [];
            nextTick(() => { this.scrollToBottomInChatLog() })
          })
        } else {
          chat_service.sendMessageText(this.chat.uuid, this.message).then(() => {
            this.ready_to_send_ws = true;
            const msg = {
              sender: {
                id: this.receiver.id,
                first_name: this.receiver.first_name,
                last_name: this.receiver.last_name,
                avatar: this.receiver.avatar,
              },
              message: this.message,
              type_message: 'text',
              sended_at: Date.now(),
              seen: false,
              sender_id: getUserData().id,
            }
            
            this.messages_format.push(msg)
            this.message = ''  
            nextTick(() => { this.scrollToBottomInChatLog() })
            setTimeout(() => {
              this.links = document.getElementsByClassName('link-url-chat')
              this.links.forEach(link => {
                link.addEventListener('click', this.openModal);
              });
            }, 50);
          });
        }
      }
    },
    scrollToBottomInChatLog() {
      const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS
      scrollEl.scrollTop = scrollEl.scrollHeight
    },
    updateChat() {
      this.$emit('update-chat', this.chat.uuid)
    },
    declinedChat() {
      this.$emit('update-chat', this.chat.uuid)
      this.can_send_message = false
      this.chat.seller_status = 'rejected';
      this.seller_status = 'rejected';
    },
    isRightSide(sender_id) {
      return getUserData().id === sender_id;
    },
  },
  watch: {
    message(val) {
      if (val.length > 0) {
        this.$refs['send-message-chat'].classList.add('background-blue')
      }
    }
  }
}
</script>
<style>

.btn-open-sider:focus {
  background: transparent;
}
.username-buy {
  display: -webkit-box;
 -webkit-line-clamp: 2;
 -webkit-box-orient: vertical;
  overflow: hidden;
  display: block;
  width: 100%;
}
.sidebar-chat > .b-sidebar {
  width: 700px !important;
  overflow-y: scroll;
}
#dropdown-attach-files .dropdown-menu {
  transform: translate3d(-60px, -89px, 0px) !important;
}

</style>
<style scoped lang="scss">
.text-overflow-title-chat-proposals {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #212529;
  font-size: .99em;
}
.input-file-send-message {
  &:focus {
    border-color: #5582f6 !important;
  }
}
.container-msg-restrincted {
  width: 100%;
  background-color: white;
  position: sticky;
  bottom: 0;
  border-radius: 1em;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border: 1px solid #5582f6;
  background-color:rgb(229, 233, 248);
  font-family: 'avenir-medium';
  padding: 2em 1em 2em 1.5em;
  overflow: hidden;
  .bar-color {
    background-color: #5582f6;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
  }
}
.send-message-chat {
  background-color: #BFBFBF;
  border-radius: 100%;
  width: 37px;
  height: 37px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 300ms;
  .message-icon-svg {
    width: 20px;
    height: 20px;
  }
}
.background-blue {
  background-color: #5582f6 !important;
}
.open-sidebar-icon {
  color: #82868b;
}
.width-proposal-right-chat, .container-right-side {
  width: 420px !important;
  max-width: 420px !important;
  min-width: 420px !important;
}
.width-proposal-right-chat {
  flex: auto;
  margin: 5.6em 0 0 0;
}
.flex-auto-container {
  flex: auto;
  border-right: 1px solid #ebe9f1;
}
.container-right-side {
  overflow-y: scroll;
  position: relative !important;
  overflow-x: hidden;
  position: relative;
}
.dropdown-sales-options {
  background-color: white;
  height: 65px;
  position: fixed;
  z-index: 100;
  width: 420px;
  border-bottom: 1px solid #ebe9f1;
  display: flex;
  justify-content: center;
}
.dropdown-sales-options-sidebar {
  width: 100%;
}
.w-90 {
  width: 95%;
}
.hour-message-container {
  font-family: 'avenir-light';
  display: block;
  width: 100%;
  padding: 0em 4.5em;
  font-size: 12px;
  font-weight: 100;
  .check-icon-seen {
    font-size: 18px;
  }
  .seen-color {
    color: rgb(13, 131, 228);
  }
}
.chat {
  .hour-message-container {
    text-align: end;
  }
}
.chat-left {
  .hour-message-container {
    text-align: start;
  }
}
.badge-date-chat {
  background-color: #dcdcdc52;
  color: rgba(0, 0, 0, 0.473);
  border-radius: 1em;
  font-family: 'avenir-light';
  font-weight: 500;
  padding: 5px
}
.loader-uploading-files {
  color: #ccc;
  background-color: white;
  position: absolute;
  padding: 5px;
  right: 0;
}
.spinner-uploading-files { 
  width: 25px;
  height: 25px;
}
.close-sidebar-icon {
  position: absolute;
  top: 1em;
  right: 1em;
}
.close-sidebar-icon:hover {
  background-color: rgba(219, 219, 219, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
.chat-application .chat-app-window .user-chats {
  background-color: white !important;
  background-image: none;
}
.chat-app-window .chats .chat-body .chat-content{
  box-shadow: none;
  border-radius: 1em;
}
.chat-app-window .chats .chat-body .chat-content {
  margin: 0 1rem 5px 0;
}
.chat-app-window .chats .chat-left .chat-body .chat-content {
  background-color: #f5f5f5;
  font-family: 'avenir-medium';
  display: block;
  position: relative;
  width: auto;
  margin: 0 0 5px 1rem;

  &::before {
    border: 10px solid #f5f5f5;
    content: '';
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent ;
    position: absolute;
    top: 0;
    left: -10px;
  }
  &::after {
    border: 10px solid transparent !important;
    content: '' !important;
  }
}
.chat-bot {
  background-color: #0059db !important;
  color: white !important;
  padding: 1em !important;
  border-radius: 0.5em !important;
  &::before {
    border: 10px solid  #0059db !important;
    content: '' !important;
    border-left-color: transparent !important;
    border-bottom-color: transparent !important;
    border-right-color: transparent !important;
    position: absolute !important;
    top: 0 !important;
    left: -10px !important;
  }
}
.hour-message {
  color: rgba(0, 0, 0, 0.473);
}
.chat-app-window .chats .chat-body .chat-content {
  background-color: #D9E7FA;
  color: black;
  font-family: 'avenir-medium';
  background-image: none;
  &::after {
    border: 10px solid #D9E7FA !important;
    content: '' !important;
    border-left-color: transparent !important;
    border-bottom-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent;
    position: absolute !important;
    right: 5px !important;
    top: 1em;
  }
}
.outline-secondary-light {
  border: 1px solid #d8d6de;
}
.outline-secondary-light:hover {
  background-color: #f1f1f1;
}
.dropdown-menu[x-placement^=top] , .dropup > .dropdown-menu, .dropdown-menu  {
  left: -4em !important;
}
.container-badges-files {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
}
.badges-files {
  display: flex;
  align-items: center;
  overflow-x: scroll;
}
.loader-inner-active-chat {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner-inner-active-chat {
  height: 6em;
  width: 6em;
  color: #acacac;
}
</style>
